import { createContext, useCallback, useContext, useRef, useEffect, useState } from "react";
import socketIOClient from "socket.io-client";
import { AdminContext, ChatType, Message } from "./adminContext";
import { Socket } from "dgram";

const configUrl = {
  socketUrl: process.env.NODE_ENV === "production" ? "https://c2c-event.goldapp.ru" : "https://c2c-event.goldapp.ru",
};

export type ContextProps = {
  joinChatAdmin: (chatId: string) => void;
  leaveChatAdmin: (chatId: string) => void;
  sendMessageSupport: (content: string, chatId: string) => void;
  socket: Socket;
  chatIdRef: React.MutableRefObject<string>;
  subscribeToUserOnliness: (userCountryId: string) => void;
  setOnline: React.Dispatch<React.SetStateAction<{ clientId: string; online: boolean }>>;
  online: { clientId: string; online: boolean };
};

export const REQUESTS_PER_PAGE = 8;
export const SocketContext = createContext({} as ContextProps);

export const SocketProvider = ({ children }: { children: JSX.Element }) => {
  const { localCountry, setChats } = useContext(AdminContext);
  const [online, setOnline] = useState({ clientId: "", online: false });
  const countryIsoCode = localCountry?.iso.toUpperCase();
  const access_token = localStorage.getItem("accessToken") || sessionStorage.getItem("accessToken");
  const [socket, setSocket] = useState(null);
  const chatIdRef = useRef("");

  const joinChatAdmin = (chatId: string) => {
    socket.emit("joinSupportChat-admin", { chatId, countryIsoCode }, (response) => {
      console.log("ADMIN JOINED TO SUPPORT CHAT:", response);
    });
  };

  const leaveChatAdmin = (chatId: string) => {
    socket.emit("leaveSupportChat-admin", { chatId, countryIsoCode }, (response) => {
      console.log("ADMIN LEFT SUPPORT CHAT:", chatId, response);
    });
  };

  const sendMessageSupport = (content: string, chatId: string) => {
    socket.emit("sendMessage-admin", { chatId, content, countryIsoCode }, (response) => {
      console.log("ADMIN SEND MESSAGE TO SUPPORT CHAT RES:", response);
    });
  };

  const subscribeToUserOnliness = useCallback(
    (userCountryId: string) => {
      socket.emit("subscribeToUserOnliness", { countryIsoCode, userCountryId: userCountryId }, (response) => {
        console.log("subscribeToUserOnliness", response);
      });
    },
    [socket, countryIsoCode],
  );

  useEffect(() => {
    if (!access_token) return;

    const socketConnector = socketIOClient(configUrl.socketUrl, {
      path: "/socket.io/",
      transports: ["polling"],
      extraHeaders: {
        Authorization: `Bearer ${access_token}`,
      },
    });

    setSocket(socketConnector);

    socketConnector.on("connect", () => {
      console.log("socket connected");
    });

    socketConnector.on("online", (message) => {
      console.log("online", message);
      setOnline(message);
    });

    socketConnector.on("newMessage", (message: Message) => {
      const addMsgToChat = (chat: string) => {
        setChats((prev) => {
          prev[chat].messages.unshift(message);
          return { ...prev, [chat]: prev[chat] };
        });
      };

      if (message.chatType === ChatType.SUPPORT) {
        addMsgToChat("supportChat");
      } else if (message.chatType === ChatType.RENT && message.chatId === chatIdRef.current) {
        addMsgToChat("debateChat");
      }
    });

    return () => {
      socketConnector.disconnect();
    };
  }, [access_token, setChats]);

  return (
    <SocketContext.Provider
      value={{
        joinChatAdmin,
        sendMessageSupport,
        socket,
        chatIdRef,
        leaveChatAdmin,
        subscribeToUserOnliness,
        online,
        setOnline,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};
