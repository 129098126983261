import type { User } from "context/usersContext";
import { get, post, del, patch } from "./api_helper";
import { Pagination } from "pages/Main/Components/AdminPanel/Options";

export type translation = {
  name?: string;
  description?: string;
  languageCode: string;
  value?: string;
};

type value = {
  translations: translation[];
};

type optionType = {
  translations: translation[];
  productGroupId: number;
  type: string;
  select: {
    translations: translation[];
    values: value[];
  };
  numericRange?: { max: number; min: number };
};

export const loginAdmin = (email: string, password: string) => {
  return post(`/auth/internal/login`, { email, password });
};

export const registerAdmin = (email: string, password: string, firstName: string, lastName: string) => {
  return post(`/auth/internal/register`, {
    email,
    password,
    passwordRepeat: password,
    firstName,
    lastName,
    role: "ADMIN",
  });
};

export const getUsers = (localCountry: string) => {
  return get(`/internal/users`, { headers: { "country-iso-code": localCountry } });
};

export const getAccount = (localCountry: string) => {
  return get(`/internal/user`, { headers: { "country-iso-code": localCountry } });
};

export const approveUser = (userId: string, role: string, localCountry: string) => {
  return post(`/internal/user/approve`, { userId, role }, { headers: { "country-iso-code": localCountry } });
};

export const editUser = (user: User, localCountry: string) => {
  return patch(`/internal/user`, user, { headers: { "country-iso-code": localCountry } });
};

export const editUserById = (user: User, id: string, localCountry: string) => {
  return patch(`/internal/user/${id}`, user, { headers: { "country-iso-code": localCountry } });
};

export const assignRole = (userId: string, role: string, localCountry: string) => {
  return patch(`/internal/assign-roles/${userId}`, { role }, { headers: { "country-iso-code": localCountry } });
};

export const activateUser = (userId: string, localCountry: string) => {
  return patch(`/internal/activate/${userId}`, { headers: { "country-iso-code": localCountry } });
};

export const deleteUser = (userId: string, localCountry: string) => {
  return del(`/internal/user/${userId}`, { headers: { "country-iso-code": localCountry } });
};

export const createWallet = (storage: string, localCountry: string) => {
  return post(`/internal/wallet`, { storage }, { headers: { "country-iso-code": localCountry } });
};

export const deleteWallet = (localCountry: string) => {
  return del(`/internal/wallet`, { headers: { "country-iso-code": localCountry } });
};

export const createCountry = (iso: string, name: string, languageIds: string[], defaultLanguageId?: number) => {
  return post(`/country`, { iso, name, languageIds, defaultLanguageId });
};

export const getCountry = (localCountry: string) => {
  return get(`/country`, { headers: { "country-iso-code": localCountry } });
};

export const updateCountry = (
  id: string,
  active: boolean,
  name: string,
  languageIds: string,
  localCountry: string,
  defaultLanguageId?: number,
) => {
  return patch(
    `/country/${id}`,
    { active, name, languageIds, defaultLanguageId },
    { headers: { "country-iso-code": localCountry } },
  );
};

export const findCountryById = (id: string, localCountry: string) => {
  return get(`/country/${id}`, { headers: { "country-iso-code": localCountry } });
};

export const deleteCountry = (id: string, localCountry: string) => {
  return del(`/country/${id}`, { headers: { "country-iso-code": localCountry } });
};

export const getContract = (localCountry: string) => {
  return get(`/contract`, { headers: { "country-iso-code": localCountry } });
};

export const mintContract = (jettonAmount: number) => {
  return post(`/contract/mint`, { jettonAmount });
};

export const burnContract = (jettonAmount: number) => {
  return post(`/contract/burn`, { jettonAmount });
};

export const transferContract = (jettonAmount: number, to: string) => {
  return post(`/contract/transfer`, { jettonAmount, to });
};

export const getLanguages = (localCountry: string) => {
  return get(`/language`, { headers: { "country-iso-code": localCountry } });
};

export const createLanguage = (code: string, name: string, localCountry: string) => {
  return post(`/language`, { code, name }, { headers: { "country-iso-code": localCountry } });
};

export const updateLanguage = (id: string, name: string, localCountry: string) => {
  return patch(`/language/${id}`, { name }, { headers: { "country-iso-code": localCountry } });
};

export const deleteLanguage = (id: string, localCountry: string) => {
  return del(`/language/${id}`, { headers: { "country-iso-code": localCountry } });
};

export const getApproveContract = () => {
  return get(`/contract/approve`);
};

export const approveContract = (localCountry: string) => {
  return post(`/contract/approve`, { headers: { "country-iso-code": localCountry } });
};

export const declineContract = (localCountry: string) => {
  return del(`/contract/approve`, { headers: { "country-iso-code": localCountry } });
};

export const getCategories = (localCountry: string) => {
  return get(`/category`, { headers: { "country-iso-code": localCountry } });
};

export const getCategoryById = (id: string, localCountry: string) => {
  return get(`/category/${id}`, { headers: { "country-iso-code": localCountry } });
};

export const createCategory = (translations: translation[], localCountry: string, parentId?: number) => {
  return post(
    `/category`,
    { translations, parentId: parentId ? parentId : undefined },
    { headers: { "country-iso-code": localCountry } },
  );
};

export const updateCategory = (translations: translation[], localCountry: string, id: string) => {
  return patch(`/category/${id}`, { translations }, { headers: { "country-iso-code": localCountry } });
};

export const deleteCategory = (id: number, localCountry: string) => {
  return del(`/category/${id}`, { headers: { "country-iso-code": localCountry } });
};

export const updateLevelCategory = (id: string, newParentId: string, localCountry: string) => {
  return patch(
    `/category/level/${id}`,
    { newParentId: +newParentId },
    { headers: { "country-iso-code": localCountry } },
  );
};

export const updateOrderCategory = (id: string, order: string, localCountry: string) => {
  return patch(`/category/order/${id}`, { newOrder: +order }, { headers: { "country-iso-code": localCountry } });
};

export const createGroup = (translations: translation[], categoryIds: number[], localCountry: string) => {
  return post(`/product-groups`, { translations, categoryIds }, { headers: { "country-iso-code": localCountry } });
};

export const getGroups = (localCountry: string) => {
  console.log(localCountry);
  return get(`/product-groups`, { headers: { "country-iso-code": localCountry } });
};

export const getGroupById = (id: string, localCountry: string) => {
  return get(`/product-groups/${id}`, { headers: { "country-iso-code": localCountry } });
};

export const updateGroupById = (translations: translation[], localCountry: string, id: number) => {
  return patch(`/product-groups/${id}`, { translations }, { headers: { "country-iso-code": localCountry } });
};

export const deleteGroup = (id: string, localCountry: string) => {
  return del(`/product-groups/${id}`, { headers: { "country-iso-code": localCountry } });
};

export const deleteGroupFromCategory = (id: number, categoryId: number, localCountry: string) => {
  return patch(
    `/product-groups/category/remove/${id}`,
    {
      categoryId,
    },
    { headers: { "country-iso-code": localCountry } },
  );
};

export const addGroupToCategory = (id: number, categoryId: number, localCountry: string) => {
  return patch(
    `/product-groups/category/add/${id}`,
    {
      categoryId,
    },
    { headers: { "country-iso-code": localCountry } },
  );
};

export const getGroupOptionSelects = (localCountry: string) => {
  return get(`/option-selects`, { headers: { "country-iso-code": localCountry } });
};

export const getGroupOptions = (localCountry: string, productGroupId: string, pagination: Pagination) => {
  const take = pagination?.take ? `&take=${pagination?.take}` : "";
  const skip = pagination?.skip ? `&skip=${pagination?.skip}` : "";

  return get(`/options?${productGroupId ? `productGroupId=${productGroupId}` : ""}${take}${skip}`, {
    headers: { "country-iso-code": localCountry },
  });
};

export const createOption = (optionType: optionType, localCountry: string) => {
  return post(`/options`, optionType, { headers: { "country-iso-code": localCountry } });
};

export const updateOption = (id: number, optionType: optionType, localCountry: string) => {
  return patch(`/options/${id}`, optionType, { headers: { "country-iso-code": localCountry } });
};

export const deleteOption = (id: string, localCountry: string) => {
  return del(`/options/${id}`, { headers: { "country-iso-code": localCountry } });
};

export const geyOptionById = (id: number, localCountry: string) => {
  return get(`/options/${id}`, { headers: { "country-iso-code": localCountry } });
};

export const updateOrderOption = (id: string, order: string, localCountry: string) => {
  return patch(`/options/${id}`, { optionOrder: +order }, { headers: { "country-iso-code": localCountry } });
};

export const getProducts = (localCountry: string, statuses?: string, productId?: string) => {
  const status = statuses ? `?statuses=${statuses}` : "";
  const id = productId ? `?id=${productId}` : "";
  return get(`/admin/product${status}${id}`, { headers: { "country-iso-code": localCountry } });
};

export const updateProductStatus = (id: number, data, localCountry: string) => {
  return patch(`/admin/product/${id}`, data, { headers: { "country-iso-code": localCountry } });
};

export const getProduct = (id: number, localCountry: string) => {
  return get(`/admin/product/${id}`, { headers: { "country-iso-code": localCountry } });
};

export const getUserById = (id: string, localCountry: string) => {
  return get(`/account/country/${id}`, { headers: { "country-iso-code": localCountry } });
};

export const createCity = (localCountry: string, city) => {
  return post(`/location/city`, city, { headers: { "country-iso-code": localCountry, "accept-language": "EN" } });
};

export const editCity = (localCountry: string, id: string, address: string, deliveryPrice: number) => {
  return patch(
    `/location/city/${id}`,
    { address, deliveryPrice },
    { headers: { "country-iso-code": localCountry, "accept-language": "EN" } },
  );
};

export const getCityWithGeozones = (localCountry: string, id: string) => {
  return get(`/location/city-info/${id}`, { headers: { "country-iso-code": localCountry } });
};

export const getCities = (localCountry: string, pagination: Pagination) => {
  const take = pagination?.take ? `&take=${pagination?.take}` : "";
  const skip = pagination?.skip ? `&skip=${pagination?.skip}` : "";
  return get(`/location/city?${take}${skip}`, { headers: { "country-iso-code": localCountry } });
};

export const deleteCity = (localCountry: string, id: string) => {
  return del(`/location/city/${id}`, { headers: { "country-iso-code": localCountry } });
};

export const createGeoZone = (localCountry: string, id: string, geo) => {
  return post(`/location/geozone/${id}`, geo, {
    headers: { "country-iso-code": localCountry, "accept-language": "EN" },
  });
};

export const deleteGeoZone = (localCountry: string, id: string) => {
  return del(`/location/geozone/${id}`, { headers: { "country-iso-code": localCountry, "accept-language": "EN" } });
};

export const autoCompleteCities = (data) => {
  return get(`https://maps.googleapis.com/maps/api/place/autocomplete/json`, {
    "Access-Control-Allow-Origin": "*",
    ...data,
  });
};

export const getChats = (localCountry: string, statuses: string[]) => {
  // const take = pagination?.take ? `&take=${pagination?.take}` : "";
  // const skip = pagination?.skip ? `&skip=${pagination?.skip}` : "";
  const queryStatuses = statuses.map((curr) => `statuses=${curr}`).join("&");
  return get(`/support-chats/admin?${queryStatuses}`, { headers: { "country-iso-code": localCountry } });
};

export const endSupportChat = (localCountry: string, chatId: string) => {
  return post(`/support-chats/admin/close-request/${chatId}`, {}, { headers: { "country-iso-code": localCountry } });
};

export const startSupportChat = (localCountry: string, chatId: string) => {
  return post(
    `/support-chats/admin/start-serve-request/${chatId}`,
    {},
    { headers: { "country-iso-code": localCountry } },
  );
};

export const getSupportChat = (cursor = 0, localCountry: string, chatId: string) => {
  // const take = pagination?.take ? `&take=${pagination?.take}` : "";
  // const skip = pagination?.skip ? `&skip=${pagination?.skip}` : "";
  return post(
    `/support-chats/admin/getRequestHistory/${chatId}`,
    { cursor, limit: 10 },
    { headers: { "country-iso-code": localCountry } },
  );
};

export const getPossibleChangeSupports = (chatId: string, localCountry: string) => {
  return get(`/support-chats/admin/get-possible-change-supports/${chatId}`, {
    headers: { "country-iso-code": localCountry },
  });
};

export const postChangeSupport = (chatId: string, supportId: string, localCountry: string) => {
  return post(
    `/support-chats/admin/change-support/${chatId}`,
    {
      supportId,
    },
    {
      headers: { "country-iso-code": localCountry },
    },
  );
};

export const getArbitrChat = (cursor = 0, localCountry: string, chatId: string) => {
  return get(`/chats/messages/${chatId}?cursor=${cursor}`, { headers: { "country-iso-code": localCountry } });
};

export const getDebate = (localCountry: string, pagination: Pagination, meMode: boolean, status?: string) => {
  return get(
    `/debate?take=${pagination?.take}&skip=${pagination?.skip}${meMode ? "&meMode=true" : ""}${
      status ? `&status=${status}` : ""
    }`,
    {
      headers: { "country-iso-code": localCountry },
    },
  );
};

export const getDebateDetails = (id: string, localCountry: string) => {
  return get(`/debate/${id}`, {
    headers: { "country-iso-code": localCountry },
  });
};

export const startDebateProcess = (id, localCountry: string) => {
  return post(`/debate/start-process/${id}`, {
    headers: { "country-iso-code": localCountry },
  });
};

export const finishDebateProcess = (id, localCountry: string, data: any) => {
  return post(`/debate/finish/${id}`, data, {
    headers: { "country-iso-code": localCountry },
  });
};
