import { Input, Spin } from "antd";
import { createRef, useEffect, useState } from "react";
import { Chat as ChatType } from "context/adminContext";
import dayjs from "dayjs";

import styles from "./Chat.module.css";
import InfiniteScroll from "react-infinite-scroll-component";

type Props = {
  chatId: string;
  sendSocketMessage: (content: string, chatId: string) => void;
  loading: boolean;
  cursor: number;
  chatData: ChatType;
  loadMore: (cursor: number) => void;
  hasMore: boolean;
};

export const Chat = ({ chatId, chatData, sendSocketMessage, loading, loadMore, cursor, hasMore }: Props) => {
  const [textMessage, setTextMessage] = useState("");
  const sendTextHandler = () => {
    sendSocketMessage(textMessage, chatId);
    setTextMessage("");
  };
  const divRef = createRef<HTMLDivElement>();

  const loadMoreData = () => {
    if (loading) return;
    loadMore(cursor);
  };

  useEffect(() => {
    if (chatId) {
      divRef.current?.scrollTo({ left: 0, top: 0 });
    }
  }, [chatId]);

  return (
    <div className={styles.chatWrapper}>
      <>
        <div className={styles.chat} id="scrollableElem" ref={divRef}>
          <InfiniteScroll
            dataLength={chatData.messages.length}
            hasMore={hasMore}
            className={styles.chat}
            loader={<Spin />}
            inverse={true}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
            next={loadMoreData}
            scrollableTarget="scrollableElem"
          >
            {chatData.messages?.map((message) => {
              if (message.authorType === "CLIENT" || message.author) {
                return (
                  <div key={message.id} className={styles.myMessages}>
                    {message.author && (
                      <div
                        className={styles.messageDate}
                      >{`${message.author.firstName} ${message.author.lastName}`}</div>
                    )}
                    <div className={styles.messageDate}>{dayjs(message.createdAt).format("MMMM D, hh:mm A")}</div>
                    <div className={styles.message}>{message.content}</div>
                  </div>
                );
              } else {
                return (
                  <div key={message.id} className={styles.otherMessages}>
                    <div className={styles.messageDate}>{dayjs(message.createdAt).format("MMMM D, hh:mm A")}</div>
                    <div className={styles.message}>{message.content}</div>
                  </div>
                );
              }
            })}
          </InfiniteScroll>
        </div>

        <div className={styles.inputBox}>
          <Input
            className={styles.input}
            value={textMessage}
            onChange={(e) => setTextMessage(e.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                sendTextHandler();
              }
            }}
          />
          <button className={styles.sendButton} onClick={sendTextHandler} />
        </div>
      </>
    </div>
  );
};
